import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

const defaultLanguage = 'en';
const supportedLanguages = [
    'en',
    'en-US',
    'en-EG',
    'en-AU',
    'en-GB',
    'en-CA',
    'en-NZ',
    'en-IE',
    'en-ZA',
    'en-JM',
    'en-BZ',
    'en-TT',
    'fr',
    'fr-FR',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-LU',
    'de',
    'de-DE',
    'de-AT',
    'de-CH',
    'de-LU',
    'de-LI'
];

export const detectLanguage = async () => {
    if (typeof window === 'undefined') {
        return defaultLanguage;
    }
    if (Capacitor.isNativePlatform()) {
        const language = await Device.getLanguageCode();
        if (supportedLanguages.includes(language.value)) {
            return language.value;
        } else {
            return defaultLanguage;
        }
    } else {
        const language = window.navigator.language;
        if (supportedLanguages.includes(language)) {
            // split on "-" and take the first part if there is a dash
            const languageParts = language.split('-');
            if (languageParts.length > 1) {
                return languageParts[0];
            }
            return language;
        } else {
            return defaultLanguage;
        }
    }
};
