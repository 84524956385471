import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { detectLanguage } from 'src/utils/helpers/i18n';

export const useRedirect = () => {
    const router = useRouter();
    const path = router.pathname;

    useEffect(() => {
        const route = async () => {
            const detectedLanguage = await detectLanguage();

            let baseUrl = `/${detectedLanguage}`;

            if (baseUrl.includes('undefined')) {
                baseUrl = baseUrl.replace('undefined', '');
            }

            if (path === '/') {
                router.replace(baseUrl);
                return;
            }
            if (path.startsWith(baseUrl) && router.route === '/404') {
                // prevent endless loop
                router.replace(baseUrl + router.route);
                return;
            }
            if (!path) {
                router.replace(baseUrl);
                return;
            }

            router.replace(baseUrl + path);
        };

        route();
    });

    return <></>;
};

export const Redirect = () => {
    useRedirect();
    return <></>;
};
